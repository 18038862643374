import "@fontsource/montserrat";
import "@fontsource/montserrat/200.css"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/900.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import "lazysizes"

import "moment/locale/pt"
